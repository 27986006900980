import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../global.css';

const RezeptanfragePage = () => {
    return (
        <div className="full-height">
            <div className="content-container common-width">
                <h2><strong>Rezeptanfrage</strong></h2>
                <h3 style={{color: "red"}}>NEU</h3>
                <p>Ab sofort bieten wir Ihnen das neue <strong>E-Rezept</strong> an. Sie können Ihre Bestellung bequem von zu Hause aus über unsere speziell eingerichtete Rezepthotline aufgeben.</p>
                <p>Dies bedeutet für Sie:</p>
                <ul>
                    <li>Es werden nur noch elektronische Rezepte (E-Rezepte) ausgestellt.</li>
                    <li>Leider sind grüne Rezepte, Privatrezepte, BTM-Rezepte und Verordnungen für Krankengymnastik oder Hilfsmittel derzeit noch ausgeschlossen.</li>
                    <li>Sie müssen keinen Ausdruck in der Praxis abholen. Mit Ihrer Versichertenkarte können Sie die E-Rezepte direkt in der Apotheke Ihrer Wahl einlösen.</li>
                    <li>Ohne vorheriges Einlesen einer gültigen Versicherungskarte können keine Rezepte vorab bereitgestellt werden. Wenn Ihre Versicherungskarte in diesem Quartal bereits eingelesen wurde, können wir die gewünschten Rezepte für Sie vorbereiten.</li>
                </ul>
                <p>Bestellungen (E-Rezepte) können in der Regel am folgenden Werktag in der Apotheke abgeholt werden.</p> <br/>
                <p><strong>Überweisungen</strong> für Routinekontrolluntersuchungen beim Facharzt können ebenfalls hier bestellt werden. <br/>
                Um Ihre Bestellung bearbeiten zu können, benötigen wir folgende Informationen: <br/> Name, Vorname, Geburtsdatum, Fachrichtung des Arztes, für den die Überweisung gewünscht wird, sowie den Anlass der Überweisung. <br/>
                    Ohne diese Diagnose können wir leider keine Überweisung ausstellen. Die gewünschten Überweisungen können ab 11 Uhr des nächsten Werktags in der Praxis abgeholt werden.</p>
                <p>Bitte geben Sie bei Überweisungswünschen auch Ihre aktuellen Beschwerden an, um eine adäquate Weiterleitung zu ermöglichen.</p>
                <p>Tipp: Um Wartezeiten in der Praxis bei der Abholung Ihrer Überweisung zu vermeiden, kommen Sie bitte, wenn möglich, nicht zu den Stoßzeiten (07:30 Uhr – 11:00 Uhr).</p>
                <h4 style={{color: "red"}}>Rezepthotline: 08734/9378332</h4>
                <p>Sprechen Sie bitte <strong>deutlich</strong> aufs Band und geben Sie folgende Informationen an: </p>
                <ul>
                    <li>Name</li>   
                    <li>Vorname</li>   
                    <li>Geburtsdatum</li>   
                    <li>Telefonnummer</li>   
                    <li>Medikamente mit Stärkeeingabe und wie die Medikamente eingenommen werden sollen, um eine präzise Bearbeitung zu gewährleisten.</li>   
                </ul>
                <h6 style={{fontWeight: 'bold'}}>Zum Beispiel: Ramipril 5/25. 1 morgens, 1 abends </h6>
            </div>
        </div>
    );
}

export default RezeptanfragePage;
