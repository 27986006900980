import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImpressumContent from './ImpressumContent';




const ImpressumPage = () => {
    return (
        <div>

            <ImpressumContent />
          
        </div>
    );
}

export default ImpressumPage;