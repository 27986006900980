import React from 'react';
import '../global.css'; // Importieren Sie die globalen Stile

const Footer = () => {
  return (
    <div className="footer">
      © {new Date().getFullYear()} Hausarztpraxis Reisbach. Alle Rechte vorbehalten.
    </div>
  );
};

export default Footer;
