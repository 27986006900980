import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './header.css';
import { FaPhoneAlt, FaBars } from 'react-icons/fa';

const CommonHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div className="header-container mb-0">
      <nav className="navbar">
        <div className="navbar-content">
          <a className="navbar-brand" href="/">
            <div className="logo-frame">
              <img src="images/Logo.png" alt="Logo" className="logo" />
            </div>
            <div className="brand-text">
              <h2>Hausarztpraxis Reisbach</h2>
              <p>Fachärzte für Allgemeinmedizin und psychosomatische Grundversorgung</p>
              <p>Dr. med. Armin Maderstorfer</p>
              <p>Dr. med. Albert Wicht</p>
            </div>
          </a>
          <a href="tel:08734938822" className="phone-number">
            <FaPhoneAlt className="phone-icon" />
            <span>Telefon: 08734/938822</span>
          </a>
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <FaBars />
        </div>
        <div ref={menuRef} className={`menu ${menuOpen ? 'open' : ''}`}>
          <Link className={`nav-link ${pathname === '/' ? 'active' : ''}`} to="/" onClick={handleLinkClick}>Startseite</Link>
          <Link className={`nav-link ${pathname === '/unsereLeistungen' ? 'active' : ''}`} to="/unsereLeistungen" onClick={handleLinkClick}>Unsere Leistungen</Link>
          <Link className={`nav-link ${pathname === '/rezeptanfrage' ? 'active' : ''}`} to="/rezeptanfrage" onClick={handleLinkClick}>Rezeptanfragen</Link>
          <Link className={`nav-link ${pathname === '/impressum' ? 'active' : ''}`} to="/impressum" onClick={handleLinkClick}>Impressum</Link>
          <button className="nav-link" disabled onClick={handleLinkClick}>Aktuelles</button>
          <button className="nav-link" disabled onClick={handleLinkClick}>Praxis</button>
          <button className="nav-link" disabled onClick={handleLinkClick}>Team</button>
          <button className="nav-link" disabled onClick={handleLinkClick}>Stellen</button>
        </div>
      </nav>
    </div>
  );
}

export default CommonHeader;
