import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UnsereLeistungenContent from './UnsereLeistungenContent';




const UnsereLeistungenPage = () => {
    return (
        <div>

            <UnsereLeistungenContent />
          
        </div>
    );
}

export default UnsereLeistungenPage;